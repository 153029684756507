import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const handleNavClick = (index) => {
    setActiveIndex(index);
    setMenuOpen(false);
  };

  const navItems = useMemo(() => [
    { text: "Overview", href: "#overview" },
    { text: "Highlights", href: "#highlights" },
    { text: "Amenities", href: "#amenities" },
    { text: "Unit Plans", href: "#unit" },
    { text: "Gallery", href: "#gallery" },
    { text: "About VTP", href: "#about" },
  ], []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = navItems.map(item => document.querySelector(item.href));
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      sections.forEach((section, index) => {
        if (section && section.offsetTop <= scrollPosition && section.offsetTop + section.offsetHeight > scrollPosition) {
          setActiveIndex(index);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navItems]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // useEffect(() => {
  //   const header = document.getElementById('header');
  //   let lastScrollTop = 0;

  //   window.addEventListener('scroll', function () {
  //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  //     if (scrollTop > lastScrollTop) {
  //       header.classList.add('header-hidden');
  //       header.classList.remove('header-visible');
  //     } else {
  //       header.classList.add('header-visible');
  //       header.classList.remove('header-hidden');
  //     }
  //     lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  //   });

  //   return () => {
  //     window.removeEventListener('scroll', () => { });
  //   };
  // }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleSticky = () => {
      const firstSection = document.querySelector(".first-section");
      if (firstSection) {
        const topBoundary = firstSection.offsetHeight;
        const isMobile = window.innerWidth <= 999;
        if (isMobile) {
          setIsSticky(true);
        } else {
          setIsSticky(window.scrollY >= topBoundary);
        }
      }
    };

    handleSticky();

    window.addEventListener("scroll", handleSticky);
    return () => {
      window.removeEventListener("scroll", handleSticky);
    };
  }, []);

  return (
    <header id="header" className={`header header_section ${isSticky ? "sticky" : ""}`}>
      <nav className="navbar navbar-expand-lg header_spacing">
        <div className="d-flex align-items-center text-start">
          <Link className="navbar-brand-2 py-0" to="/" onClick={scrollToTop}>
            <img src="/images/newearthone_logo2.webp" alt="logo" />
          </Link>
          <Link className="navbar-brand brand-2 py-0 m-0" to="/" onClick={scrollToTop}>
            <img src="/images/luxe_logo1.webp" alt="logo" />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mobile_toggle"
          onClick={toggleMenu}
        >
          {menuOpen ? (
            <div className="close-btn-icon">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Menu / Close_MD">
                  <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>
            </div>
          ) : (
            <div className="open-btn-icon">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6H20M4 12H14M4 18H9" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          )}
        </button>
        <div className={`collapse navbar-collapse text-center ${menuOpen ? 'show' : ''}`} id="mobile_toggle">
          <ul className="navbar-nav m-auto mt-0 mb-2 mb-lg-0">
            {navItems.map((item, index) => (
              <li
                className={`nav-item ${!item.noBorder ? "link-border" : ""} ${activeIndex === index ? "active" : ""}`}
                key={index}
                onClick={() => handleNavClick(index)}
              >
                <a className="nav-link" href={item.href}>
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
          <ul className="header-btn-container">
            <li>
              <a href="tel:08065206010" className="phone-btn">
                <img src="/images/call.svg" alt="logo" />
                08065206010
              </a>
            </li>
            <li>
              <button className="header-btn" data-bs-toggle="modal" data-bs-target="#enquirynowmodal">ENQUIRE NOW</button>
            </li>
            <li>
              <Link className="navbar-brand py-0 m-0" href="/" onClick={scrollToTop}>
                <img src="/images/luxe_logo1.webp" alt="logo" />
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
