import React from "react";

const Footer = () => {
  return (
    <>
      <div className="footer-new-desc side_spacing section_spacing">
        <div className="footer-new-desc-bg-overlay"></div>
        <img src="/images/loc-bg.webp" alt="location-bg" className="footer-new-desc-bg" />
        <h3>
          Find Your Perfect Dream Home <br className="d-md-none d-block" /> with VTP Realty
        </h3>
        <p>
          Explore a range of premium residential projects in Pune by VTP Realty, designed to cater to your lifestyle and aspirations. From contemporary apartments to elegant simplex and duplex flats in Pune , our offerings redefine modern living.
        </p>
        <p>
          With exciting new launch projects in Pune , our developments are designed to provide seamless connectivity and world-class amenities. Whether you’re seeking flats for sale in Pune for your family or as an investment, we have options that combine comfort and convenience.
        </p>
        <p className="mb-0">
          Our meticulously crafted apartments in Pune are designed to blend modern aesthetics with practical functionality, ensuring a vibrant and fulfilling living experience. With VTP Realty, your dream home is closer than ever.
        </p>
      </div>
      <section id="footer" className="footer_section side_spacing">
        <div className="section_top_large">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4 text-center">
              <div className="footer-scanner-img">
                <img
                  src="/images/footer-imgs/QR-phase1.png"
                  alt="QR Code 1"
                />
              </div>
              <span className="footer-scanner-text">
                Earth 1 By VTP Luxe PHASE 1
              </span>
              <div className="d-flex align-items-center justify-content-center gap-1">
                <div>
                  <span className="mahalogo">
                    MahaRERA Reg. No: &nbsp; P52100048489
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 text-center">
              <div className="footer-scanner-img">
                <img
                  src="/images/footer-imgs/QR-phase2.png"
                  alt="QR Code 1"
                />
              </div>
              <span className="footer-scanner-text">
                Earth 1 By VTP Luxe PHASE 2
              </span>
              <div className="d-flex align-items-center justify-content-center gap-1">
                <div>
                  <span className="mahalogo">
                    MahaRERA Reg. No: &nbsp; P52100051025
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 text-center">
              <div className="footer-scanner-img">
                <img
                  src="/images/footer-imgs/QR-phase3.webp"
                  alt="QR Code 1"
                />
              </div>
              <span className="footer-scanner-text">
                Earth 1 By VTP Luxe PHASE 3
              </span>
              <div className="d-flex align-items-center justify-content-center gap-1">
                <div>
                  <span className="mahalogo">
                    MahaRERA Reg. No: &nbsp; P52100052414
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <p className="footer_scan_text">
                All projects are registered under MahaRERA and is available on website - <a href="https://maharera.mahaonline.gov.in" target="_blank" rel="noopener noreferrer">http://maharera.mahaonline.gov.in/</a>
              </p>
            </div>
            <hr />
            <div className="footer-text-container">
              <p className="text-center mb-0">
                &copy; Copyright 2025 Official VTP Realty Website. All Rights Reserved.
              </p>
              <p className=" text-center mb-1">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#disclaimerModal"
                  className="disc-btn"
                  style={{ cursor: "pointer", border: 'none', background: 'none' }}
                >
                  Disclaimer
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
