export const highlightsData = [
  {
    imgSrc: "/images/ProjectHightlights/apartments.png",
    alt: "Image 1",
    title: "Tower",
    description: "35 storey in the launched towers",
  },
  {
    imgSrc: "/images/ProjectHightlights/gate.png",
    alt: "Image 2",
    title: "Entrance",
    description: "Grand entrance gate with security cabin",
  },
  {
    imgSrc: "/images/ProjectHightlights/home-automation.png",
    alt: "Image 3",
    title: "Smart Home Automation",
    description:
      "Provision for controlling Lights, AC, Fan & Geyser via a mobile app",
  },
  // {
  //   imgSrc: "/images/ProjectHightlights/digital-door.png",
  //   alt: "Image 4",
  //   title: "Digital Lock",
  //   description: "Premium veneer finish main door with digital lock",
  // },
  {
    imgSrc: "/images/ProjectHightlights/electric-generator.png",
    alt: "Image 5",
    title: "4+ Acres of Larger than Life Amenities",
    description: "A larger than life arena with world class amenities for people of all age groups.",
  },
  // {
  //   imgSrc: "/images/ProjectHightlights/power-bank.png",
  //   alt: "Image 6",
  //   title: "Charging Point",
  //   description:
  //     "Provision for electric vehicles charging points in common area",
  // },
  {
    imgSrc: "/images/ProjectHightlights/shield.png",
    alt: "Image 7",
    title: "Security",
    description:
      "3-tier security system and video door phone connected to the smartphone",
  },
  {
    imgSrc: "/images/ProjectHightlights/marble.png",
    alt: "Image 8",
    title: "Marble/Large Windows",
    description: "Large sized marble finish glazed vitrified tiles",
  },
];

export const benefitsData = [
  {
    imgSrc: "/images/LiveClose/icon-benefits01.svg",
    alt: "icon-1",
    title: "Easy Commute",
    description:
      "Stay Connected, Every area is accessible in a very short span of time",
  },
  {
    imgSrc: "/images/LiveClose/icon-benefits02.svg",
    alt: "icon-2",
    title: "Hinjewadi IT Park",
    description:
      "Don't waste your time travelling when it comes to work. Office areas are close by.",
  },
  {
    imgSrc: "/images/LiveClose/icon-benefits03.svg",
    alt: "icon-3",
    title: "Top Notch Schools & Colleges",
    description:
      "All top schools & colleges in the near vicinity of the project",
  },
  {
    imgSrc: "/images/LiveClose/icon-benefits04.svg",
    alt: "icon-4",
    title: "Party & Entertainment Avenues",
    description:
      "It's not bad to take some time out from your busy schedule and enjoy the life around you.",
  },
];

export const Amenitiesimages = [
  "/images/Amenities/amenities1.webp",
  "/images/Amenities/amenities2.webp",
  "/images/Amenities/amenities3.webp",
  "/images/Amenities/amenities4.webp",
  "/images/Amenities/amenities5.webp",
  "/images/Amenities/amenities6.webp",
  "/images/Amenities/amenities7.webp",
];

export const OnlyAtVTPData = [
  {
    img: "/images/onlyatvtp/icon1.svg",
    title: "4 acres of luxurious lifestyle amenities",
  },
  {
    img: "/images/onlyatvtp/icon2.svg",
    title: "World class extravagent 2 level Clubhouse with party lawn",
  },
  {
    img: "/images/onlyatvtp/icon3.svg",
    title: "Indoor & outdoor work from home space within project",
  },
  {
    img: "/images/onlyatvtp/icon4.svg",
    title: "Spacious & Fully Equipped Gymnasium with changing rooms",
  },
  {
    img: "/images/onlyatvtp/icon5.svg",
    title: "Large size vanishing edge designer swimming pool with jacuzzi",
  },
  {
    img: "/images/onlyatvtp/icon6.svg",
    title: "Indoor & outdoor sports arenas for all age groups within project",
  },
  {
    img: "/images/onlyatvtp/icon7.svg",
    title: "Vehicle free zone 1+ KM of jogging & cycling track",
  },
  {
    img: "/images/onlyatvtp/icon8.svg",
    title: "3 Lush Green Landscape Lawns",
  },
  {
    img: "/images/onlyatvtp/icon9.svg",
    title: "Digital Door Lock with Smart Home Automation Features",
  },
  {
    img: "/images/onlyatvtp/icon10.svg",
    title: "Grand entrance with access control stylish lobbies for each tower",
  },
  {
    img: "/images/onlyatvtp/icon11.svg",
    title: "Multi level Security with video intercom to ensure safety & privacy",
  }
]

export const unitData = [
  {
    id: 1,
    image: "/images/unitPrice/2bhk.webp",
    alt: "2bhk",
    description: "2 BHK - 673 - 861 sq.ft",
  },
  {
    id: 2,
    image: "/images/unitPrice/3bhk-1.webp",
    alt: "3bhk-1",
    description: "3 BHK - 1446 sq.ft",
  },
  {
    id: 3,
    image: "/images/unitPrice/3bhk-1.webp",
    alt: "3bhk-1",
    description: "4 BHK - 1766 sq.ft",
  },
  {
    id: 4,
    image: "/images/unitPrice/4bhk.webp",
    alt: "4bhk",
    description: "SIMPLEX - 1534 - 1540 sq.ft",
  },
  {
    id: 5,
    image: "/images/unitPrice/4bhk.webp",
    alt: "4bhk",
    description: "DUPLEX - 1346 - 1722 sq.ft",
  },
]