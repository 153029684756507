import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const ThankYou = () => {
  const downloadBtn = useRef();
  const [thankyouMsg, setThankyouMsg] = useState({
    userName: "",
    Mobile: "",
    Email: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const msg = JSON.parse(localStorage.getItem("formData"));
    if (!msg?.FirstName) {
      navigate("/");
      return;
    }
    if (msg?.FirstName) {
      setThankyouMsg({
        ...thankyouMsg,
        userName: msg.FirstName + " " + msg.LastName,
        Mobile: msg.CountryCode + " " + msg.Mobile,
        Email: msg.Email,
      });
    }

    const downloadBrochure = localStorage.getItem("downloadBrochure");
    if (downloadBrochure === "yes") {
      downloadBtn.current.click();
      localStorage.removeItem("downloadBrochure");
    }
  }, [navigate, thankyouMsg]);

  return (
    <>
      <Helmet>
        <title>VTP EarthOne - Thank You</title>
      </Helmet>
      <section id="thank-you-bg" className="bg-container">
        <div className="main-container">
          <div className="row justify-content-center align-items-center m-0">
            <div className="col-md-12 px-0">
              <div className="thankyou-logo">
                <a href="/">
                  <img src="/images/thankyou-logo.webp" alt="Brand-Logo" />
                </a>
              </div>
              <a
                style={{ opacity: "0", height: "0" }}
                href={`${process.env.REACT_APP_FRONTEND_URL}pdf/Earth1-Brochure.pdf`}
                ref={downloadBtn}
                download="Earth1-Brochure"
              >
                Earth1-Brochure
              </a>
              <h1 className="text-white text-center mt-2">Thank You</h1>
              <div className="user-details">
                <p>Full Name: {thankyouMsg?.userName}</p>
                <p>Phone: {thankyouMsg?.Mobile}</p>
                <p>Email: {thankyouMsg?.Email}</p>
              </div>
              <div className="text-center">
                <h4 className="text-white">VTP Earth One - Mahalunge</h4>
              </div>
              <nav className="home">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Thank You</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThankYou;
