import React from "react";
import Counter from "./Counter";

const Snapshot = () => {
  return (
    <section
      id="snapshot"
      className="snapshot_section side_spacing section_top_small section_btm_large"
    >
      <div className="counter-wrapper row d-flex justify-content-start">
        <div className="col-md-4">
          <h2 className="section-title title_spacing">
            BRAND <br /><span className="title-bold">SNAPSHOT</span>
          </h2>
        </div>
        <div className="col-xl-8 col-lg-8 col-md-8 col-12">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-6 custom-margin-2">
              <Counter
                endValue="20,000 +"
                label="HAPPY CUSTOMERS & ADDING MORE EVERY MONTH"
              />
            </div>
            <div className="col-lg-4 col-md-4 col-6 custom-margin-2">
              <Counter
                endValue="39 +"
                label="YEARS OF EXPERIENCE IN CONSTRUCTION"
              />
            </div>
            <div className="col-lg-4 col-md-4 col-6 custom-margin-2">
              <Counter endValue="27 +" label="PROJECTS DELIVERED" />
            </div>
            <div className="col-lg-4 col-md-4 col-6 custom-margin-2">
              <Counter endValue="10 M" label="SQ.FT DELIVERED" />
            </div>
            <div className="col-lg-4 col-md-4 col-6 custom-center custom-margin-2">
              <Counter
                endValue="3 Cr"
                label="SQ.FT OF SIMULTANEOUS CONSTRUCTION"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Snapshot;
